import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const PurchaseEducationPageTemplate = ({
  content,
  contentComponent,
  title,
  subtitle,
  image,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section>
      {helmet || ""}
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `center`,
          minHeight: 450,
        }}
      >
        <div className={"container has-text-centered"}>
          <h2 className="has-text-weight-bold is-size-1 has-text-white">
            {title}
          </h2>
          <h5 className={"has-text-weight-bold is-size-5 has-text-white"}>
            {subtitle}
          </h5>
        </div>
      </div>

      <div className="section content">
        <div className="container has-text-centered ">
          <PostContent content={content} />
        </div>
      </div>
    </section>
  );
};

PurchaseEducationPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
};

const PurchaseEducation = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PurchaseEducationPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        image={post.frontmatter.image}
        helmet={
          <Helmet titleTemplate="Purchase Education | Escondido Mortgage Lender">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`Purchase Education`} />
          </Helmet>
        }
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
      />
    </Layout>
  );
};

PurchaseEducation.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PurchaseEducation;

export const pageQuery = graphql`
  query PurchaseEducation($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
